import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Container } from '@material-ui/core'
import { get } from 'lodash'

import Loading from '../../../components/Loading'
import PageHeader from '../../../components/PageHeader'
import ProtectedPage from '../../../components/ProtectedPage'
import { SAURON_ROLE } from '../../../userRoles'

import Options from './components/Options'
import ProfilesList from './components/ProfilesList'
// import ProfilesDummyDataNew from './data/'

// import styles from './styles.module.css'

/* const VACANCIES = gql`
  query GetVacancies {
    visibleVacancies {
      _id
      title
    }
  }
`*/

const COMPANIES = gql`
  query GetCompanies {
    companies {
      _id
      name
    }
  }
`

const CANDIDATES = gql`
  query FindCandidates($input: FindCandidatesInput!) {
    findCandidates(input: $input) {
      _id
      fullName
      phone
      resume
      video
      applications {
        _id
        vacancyId {
          _id
        }
        vacancyTitle
        area
        seniorityLevel
        companyId {
          _id
        }
        companyName
        applicationId {
          _id
        }
        status
        applierName
        applierEmail
        expectedSalary
        keywords
        createdAt
      }
      createdAt
    }
  }
`

const Candidates = () => {
  const [getCandidates, { data, loading /* , refetch*/ }] = useLazyQuery(
    CANDIDATES,
    {
      fetchPolicy: 'network-only',
    },
  )

  // const { loading: vacancyLoading, data: vacancyData } = useQuery(VACANCIES)

  const { loading: companiesLoading, data: companiesData } = useQuery(COMPANIES)

  const [companies, setCompanies] = useState([])
  const [profiles, setProfiles] = useState([])
  const [maxItems, setMaxItems] = useState(5)
  const [filters, setFilters] = useState({
    name: '',
    companyId: '',
    expectedSalary: -1,
    area: 'ALL',
    seniorityLevel: '',
    keywords: [],
  })

  useEffect(() => {
    const profiles = get(data, 'findCandidates', [])
    // const vacancies = get(vacancyData, 'visibleVacancies', [])
    // setVacancies(vacancies)
    const companies = get(companiesData, 'companies', [])
    setProfiles(profiles)
    setCompanies(companies)
  }, [data, companiesData /* , vacancyData*/])

  const handleSearch = () => {
    const input = { ...filters }

    if (input.name === 'ALL' || input.name === '') delete input.name
    if (input.companyId === 'ALL' || input.companyId === '')
      delete input.companyId
    if (input.expectedSalary === -1 || isNaN(input.expectedSalary))
      delete input.expectedSalary
    if (input.area === 'ALL' || input.area === '') delete input.area
    if (input.seniorityLevel === 'ALL' || input.seniorityLevel === '')
      delete input.seniorityLevel
    if (Object.keys(input).length)
    if (!input.keywords.length) delete input.keywords

    getCandidates({
      variables: {
        input,
      },
    })
  }

  return (
    <ProtectedPage allowedRoles={[SAURON_ROLE]}>
      <PageHeader title="Buscador unificado de Candidatos" type="candidates" />
      <Container>
        <Options
          filters={filters}
          setFilters={setFilters}
          handleSearch={handleSearch}
          companies={companies}
          loading={loading}
          maxItems={maxItems}
          setMaxItems={setMaxItems}
        />
        {loading || companiesLoading ? (
          <Loading />
        ) : (
          <ProfilesList profiles={profiles} maxItems={maxItems} />
        )}
      </Container>
    </ProtectedPage>
  )
}

export default Candidates
