import React, { useCallback, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import CallIcon from '@material-ui/icons/Call'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import YouTubeIcon from '@material-ui/icons/YouTube'
import dayjs from 'dayjs'

import Pagination from '../../../../../components/Pagination'
import { getPageItems } from '../../../../../helpers/paginated'

import VideoModal from './components/VideoModal'

import styles from './styles.module.css'
const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN

const statusDictionary = {
  PENDING_CONTACT: 'Por Contactar',
  VIDEO_REQUESTED: 'Video Solicitado',
  VIDEO_SENT: 'Video Recibido',
  APPLICANT_PRESENTED: 'Candidato Presentado',
  HIRED: 'Contratado',
  TO_UNLINK: 'Por Desvincular',
  UNLINKED: 'Desvinculado',
}

const ApplicantsList = ({ profiles, maxItems }) => {
  const [elements, setElements] = useState({})

  const [open, setOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const handleClickOpen = videoContent => {
    setModalContent(videoContent)
    setOpen(true)
  }

  const setPage = useCallback(
    (page = 1) => {
      if (!profiles) return
      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(profiles, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [maxItems, profiles],
  )

  useEffect(() => {
    setPage()
  }, [profiles, maxItems, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      {elements.exists ? (
        elements.items.map(profile => (
          <>
            <Accordion
              key={`${profile._id}`}
              className={styles.applicationListItemCandidates}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${profile._id}-content`}
                id={`${profile._id}-content`}>
                <div className={styles.containerText}>
                  <Typography className={styles.textName}>
                    {profile.fullName}
                  </Typography>
                </div>
                <div className={styles.innerIcons}>
                  {profile.resume ? (
                    <a
                      href={`${CDN_URL}/${profile.resume}`}
                      target="_blank"
                      download
                      onClick={event => event.stopPropagation()}
                      onFocus={event => event.stopPropagation()}>
                      <FindInPageIcon className={styles.Icons} />
                    </a>
                  ) : (
                    <a>
                      <FindInPageIcon className={styles.IconsDisabled} />
                    </a>
                  )}
                </div>
                <div className={styles.innerIcons}>
                  {profile.video ? (
                    <a
                      onFocus={event => event.stopPropagation()}
                      onClick={function(event) {
                        handleClickOpen({
                          id: profile.id,
                          video: profile.video,
                          fullName: profile.fullName,
                          vacancyTitle: profile.videoVacancyTitle,
                        })
                        event.stopPropagation()
                      }}>
                      <YouTubeIcon className={styles.Icons} />
                    </a>
                  ) : (
                    <a>
                      <YouTubeIcon className={styles.IconsDisabled} />
                    </a>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails className={styles.infoWrapper}>
                <div className={styles.infoWrapperDetails}>
                  <div className={styles.leftData}>
                    <Typography>
                      <b>Teléfono:</b> {profile.phone}
                    </Typography>
                  </div>
                  <div className={styles.rightData}>
                    <div className={styles.innerIcons}>
                      <a href={`tel:${profile.phone}`} target="_blank">
                        <CallIcon className={styles.Icons} />
                      </a>
                    </div>
                    <div className={styles.innerIcons}>
                      <a
                        href={`https://wa.me/${profile.phone}`}
                        target="_blank">
                        <WhatsAppIcon className={styles.Icons} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.infoWrapperApplications}>
                  {profile.applications.map(details => (
                    <div
                      className={styles.applicationsDetails}
                      key={details._id}>
                      <Typography>
                        {details.vacancyTitle ? (
                          <b>{details.vacancyTitle}</b>
                        ) : null}{' '}
                        {details.companyName ? (
                          <i>
                            {'('}
                            {details.companyName}
                            {')'}
                          </i>
                        ) : null}
                        <span>{statusDictionary[details.status]}</span>
                        <span>
                          <b>Postuló el:</b>{' '}
                          {dayjs(details.createdAt).format('DD-MM-YYYY')}
                          <br />
                          <b>Expectativa de renta:</b>{' '}
                          {details.expectedSalary.toLocaleString('es')}
                          <br />
                          <b>Email de postulación:</b>{' '}
                          {details.applierEmail.toLocaleString('es')}
                          <br />
                          <b>Palabras clave:</b>{' '}
                          {details.keywords && details.keywords.join(', ')|| ' -'}
                        </span>
                      </Typography>
                      {details.vacancyId ? (
                        <div className={styles.detailsContact}>
                          <a
                            href={`/admin/vacancies/${details.vacancyId._id}`}
                            target="_blank">
                            <OpenInNewIcon className={styles.Icons} />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        ))
      ) : (
        <>
          <Typography className={styles.message}>
            <b>Por favor, defina los filtros para realizar la busqueda.</b>
          </Typography>
        </>
      )}
      {elements.exists && renderPagination()}
      <VideoModal content={modalContent} open={open} setOpen={setOpen} />
    </>
  )
}

export default ApplicantsList
